<template>
  <div class="notas-credito-electronicas">
    <v-data-table
      :headers="headers"
      :items="notas"
      :footer-props="{
        itemsPerPageText: 'Facturas',
        itemsPerPageOptions: [10, 25, 50, -1],
      }"
      :options="{
        itemsPerPage: 50,
      }"
      :loading="loading_t"
      :search="search"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-col cols="4" class="px-0">
            <v-text-field
              outlined
              rounded
              dense
              append-icon="mdi-magnify"
              label="Buscar"
              class="mt-6"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:item.folio="{ item }">
        <router-link :to="'/detalleNotaCredito/' + item.id">
          {{ item.folio }}
        </router-link>
      </template>
      <template v-slot:item.cufe="{ item }">
        <v-tooltip top max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              x-small
              color="primary"
              v-on="on"
              v-bind="attrs"
              @click="copy_link(item.cufe)"
              >Copiar CUDE</v-btn
            >
          </template>
          <span style="width:300px; word-wrap: break-word;">
            {{ item.cufe }}
          </span>
        </v-tooltip>
        <div id="copy"></div>
      </template>
      <template v-slot:item.estadoDian="{ item }">
        <v-chip small color="primary" v-if="item.estadoDian == 'Pendiente'">
          Aprobada
        </v-chip>
        <v-chip small color="success" v-if="item.estadoDian == 'Aprobada'">
          Aprobada
        </v-chip>
        <v-tooltip top max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-on="on"
              v-bind="attrs"
              small
              color="error"
              v-if="item.estadoDian == 'Rechazada'"
            >
              Rechazada
            </v-chip>
          </template>
          <span>
            {{ item.error_message }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.consultar="{ item }">
        <v-btn
          x-small
          color="primary"
          :loading="actions.consult == item.id"
          :disabled="
            actions.consult == item.id ||
              (actions.consult != item.id && actions.consult != null)
          "
          @click="generateConsult(item)"
        >
          Consultar
        </v-btn>
      </template>
      <template v-slot:item.descargar="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              text
              color="primary"
              v-on="on"
              v-bind="attrs"
              v-if="item.estadoDian == 'Aprobada'"
              @click="downloadPDF(item)"
              :loading="actions.download == item.id"
              :disabled="
                actions.download == item.id ||
                  (actions.download != item.id && actions.download != null)
              "
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar PDF</span>
        </v-tooltip>
      </template>
      <template v-slot:item.enviar="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              text
              color="success"
              v-on="on"
              v-bind="attrs"
              v-if="item.estadoDian == 'Aprobada'"
              @click="sendMailZip(item)"
              :loading="actions.send == item.id"
              :disabled="
                actions.send == item.id ||
                  (actions.send != item.id && actions.send != null)
              "
            >
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </template>
          <span>Enviar PDF</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "notasCredito",
  props: ["notas", "baseURL", "auth_token", "loading_t"],
  data: () => ({
    actions: {
      consult: null,
      send: null,
      download: null,
    },
    headers: [
      {
        text: "Fecha Nota",
        value: "fecha",
        align: "start",
      },
      {
        text: "# Nota sistema",
        value: "folio",
      },
      {
        text: "# Nota DIAN",
        value: "folio_dian",
      },
      {
        text: "CUDE",
        value: "cufe",
        filterable: false,
      },
      {
        text: "Estado",
        value: "estadoDian",
        filterable: false,
        width: 100,
      },
      {
        text: "Consultar",
        value: "consultar",
        sortable: false,
        width: 100,
      },
      {
        text: "",
        value: "descargar",
        sortable: false,
      },
      {
        text: "",
        value: "enviar",
        width: 50,
      },
    ],
    search: "",
  }),
  methods: {
    generateConsult(item) {
      this.actions.consult = item.id;
      this.$emit("generateConsult", {
        item,
        type: "nota",
        ref: "notasCredito",
      });
    },
    downloadPDF(item) {
      this.actions.download = item.id;
      this.$emit("downloadPDF", { item, ref: "notasCredito" });
    },
    sendMailZip(item) {
      this.actions.send = item.id;
      let file = item.attached;

      const body = {
        route: "/send_nota_electronico",
        params: {
          file,
          id: item.id,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          } else {
            let alert = {
              alert: true,
              color: "error",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          }
        })
        .finally(() => (this.actions.send = null));
    },
    copy_link(cufe) {
      const url = cufe;
      let input = document.createElement("input");
      input.setAttribute("value", url);
      let copy = document.getElementById("copy");
      copy.appendChild(input);
      input.select();
      document.execCommand("copy");
      copy.removeChild(input);
    },
  },
};
</script>
