var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"facturas-electronicas"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.facturas,"footer-props":{
      itemsPerPageText: 'Facturas',
      itemsPerPageOptions: [10, 25, 50, -1],
    },"options":{
      itemsPerPage: 50,
    },"loading":_vm.loading_t,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.folio",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":'/detallefactura/' + item.id}},[_vm._v(" "+_vm._s(item.folio)+" ")])]}},{key:"item.cufe",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.copy_link(item.cufe)}}},'v-btn',attrs,false),on),[_vm._v("Copiar CUFE")])]}}],null,true)},[_c('span',{staticStyle:{"width":"300px","word-wrap":"break-word"}},[_vm._v(" "+_vm._s(item.cufe)+" ")])]),_c('div',{attrs:{"id":"copy"}})]}},{key:"item.estadoDian",fn:function(ref){
    var item = ref.item;
return [(item.estadoDian == 'Pendiente')?_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" Aprobada ")]):_vm._e(),(item.estadoDian == 'Aprobada')?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v(" Aprobada ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(item.estadoDian == 'Rechazada')?_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":"error"}},'v-chip',attrs,false),on),[_vm._v(" Rechazada ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.error_message)+" ")])])]}},{key:"item.consultar",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","color":"primary","loading":_vm.actions.consult == item.id,"disabled":_vm.actions.consult == item.id ||
            (_vm.actions.consult != item.id && _vm.actions.consult != null)},on:{"click":function($event){return _vm.generateConsult(item)}}},[_vm._v(" Consultar ")])]}},{key:"item.descargar",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(item.estadoDian == 'Aprobada')?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","color":"primary","loading":_vm.actions.download == item.id,"disabled":_vm.actions.download == item.id ||
                (_vm.actions.download != item.id && _vm.actions.download != null)},on:{"click":function($event){return _vm.downloadPDF(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Descargar PDF")])])]}},{key:"item.enviar",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(item.estadoDian == 'Aprobada')?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","color":"success","loading":_vm.actions.send == item.id,"disabled":_vm.actions.send == item.id ||
                (_vm.actions.send != item.id && _vm.actions.send != null)},on:{"click":function($event){return _vm.sendMailZip(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-send")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Enviar PDF")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }