<template>
  <div class="facturas mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Listado de documentos electrónicos
          </v-card-title>
          <v-card-text>
            <v-tabs v-model="tab" :color="color">
              <v-tab href="#tab-1">
                Facturas Electronicas
              </v-tab>
              <v-tab href="#tab-2">
                Notas Credito Electronicas
              </v-tab>
              <v-tabs-slider></v-tabs-slider>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <facturas-electronicas
                  class="mt-4"
                  ref="facturasElectronicas"
                  :facturas="lista"
                  :baseURL="baseURL"
                  :auth_token="auth_token"
                  :loading_t="loading_t"
                  v-on:generateConsult="generateConsult($event)"
                  v-on:downloadPDF="downloadPDF($event)"
                />
              </v-tab-item>
              <v-tab-item value="tab-2">
                <notas-credito
                  class="mt-4"
                  ref="notasCredito"
                  :notas="lista_notas"
                  :baseURL="baseURL"
                  :auth_token="auth_token"
                  :loading_t="loading_t"
                  v-on:generateConsult="generateConsult($event)"
                  v-on:downloadPDF="downloadPDF($event)"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import facturasElectronicas from "../../components/docElectronicos/Facturas.vue";
import notasCredito from "../../components/docElectronicos/NotasCredito.vue";
import * as moment from "moment";
export default {
  components: {
    facturasElectronicas,
    notasCredito,
  },
  data: () => ({
    color: "primary",
    tab: "tab-1",
    facturas: [],
    notas: [],
    auth_token: null,
    baseURL: "https://apirestdian.luxcultury.com/api",
    loading_t: false,
  }),
  methods: {
    loadFacturas() {
      this.loading_t = true;
      const body = {
        route: "/documentos_electronicos",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.facturas = response.data.data.facturas;
            this.notas = response.data.data.notas;
            this.auth_token = response.data.auth_token;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    generateConsult(event) {
      let prefix = "";
      if (event.type == "nota") prefix = "NCS-NC";
      else prefix = "FES-SETP";

      let url = "";

      let data;

      if (event.item.zip_key != null) {
        url = `zip/`;

        data = {
          sendmail: false,
          sendmailtome: false,
          atacheddocument_name_prefix: prefix,
        };
      } else {
        url = `document/`;

        data = {
          sendmail: false,
          atacheddocument_name_prefix: null,
        };
      }

      axios({
        method: "POST",
        baseURL: this.baseURL,
        url: `/ubl2.1/status/${url}${
          url == "zip/" ? event.item.zip_key : event.item.cufe
        }`,
        headers: {
          Authorization: `Bearer ${this.auth_token}`,
        },
        data: data,
      })
        .then((response) => {
          if (response.status == 200) {
            let dian_state = 0;
            let error_message = null;

            let respuesta = "";

            let color = "success";

            if (url == "zip/") {
              respuesta =
                response.data.ResponseDian.Envelope.Body.GetStatusZipResponse
                  .GetStatusZipResult.DianResponse.StatusMessage;
              if (
                response.data.ResponseDian.Envelope.Body.GetStatusZipResponse
                  .GetStatusZipResult.DianResponse.IsValid == "true"
              )
                dian_state = 1;
              else {
                error_message = JSON.stringify(
                  response.data.ResponseDian.Envelope.Body.GetStatusZipRespons
                    .GetStatusZipResult.DianResponse.ErrorMessage
                );
                color = "error";
              }
            } else {
              respuesta =
                response.data.ResponseDian.Envelope.Body.GetStatusResponse
                  .GetStatusResult.StatusMessage;
              if (
                response.data.ResponseDian.Envelope.Body.GetStatusResponse
                  .GetStatusResult.IsValid == "true"
              )
                dian_state = 1;
              else {
                error_message = JSON.stringify(
                  response.data.ResponseDian.Envelope.Body.GetStatusResponse
                    .GetStatusResult.ErrorMessage
                );
                color = "error";
              }
            }

            let alert = {
              alert: true,
              color,
              text: respuesta,
            };
            this.$store.dispatch("setAlert", alert);

            if (dian_state != event.item.dian_state || !event.item.dian_state) {
              let route = "/update_f_electronica";

              if (event.type == "nota") route = "update_n_electronica";

              const body = {
                route: route,
                data: {
                  update: {
                    dian_state,
                    error_message,
                  },
                  factura: event.item.id,
                  nota: event.item.id,
                },
              };

              this.updateDocument(body);
            }
          }
        })
        .catch((error) => {
          if (url == "zip/") {
            this.getStatusDocument(
              {
                sendmail: false,
                atacheddocument_name_prefix: null,
              },
              event.item,
              event.type
            );
          } else {
            let alert = {
              alert: true,
              color: "error",
              text: "La factura no se registro correctamente en la DIAN",
            };
            this.$store.dispatch("setAlert", alert);
          }
        })
        .finally(() => (this.$refs[event.ref].actions.consult = null));
    },
    getStatusDocument(data, item, type) {
      axios({
        method: "POST",
        baseURL: this.baseURL,
        url: `/ubl2.1/status/document/${item.cufe}`,
        headers: {
          Authorization: `Bearer ${this.auth_token}`,
        },
        data: data,
      })
        .then((response) => {
          let dian_state = 0;
          let error_message = null;
          if (
            response.data.ResponseDian.Envelope.Body.GetStatusResponse
              .GetStatusResult.IsValid == "true"
          )
            dian_state = 1;
          else
            error_message = JSON.stringify(
              response.data.ResponseDian.Envelope.Body.GetStatusResponse
                .GetStatusResult.ErrorMessage
            );

          let alert = {
            alert: true,
            color: "success",
            text: response.data.message,
          };
          this.$store.dispatch("setAlert", alert);

          if (dian_state != item.dian_state) {
            let route = "/update_f_electronica";

            if (type == "nota") route = "update_n_electronica";

            const body = {
              route: route,
              data: {
                update: {
                  dian_state,
                  error_message,
                },
                factura: item.id,
                nota: item.id,
              },
            };

            this.updateDocument(body);
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "success",
            text: "La factura no se registro correctamente en la DIAN",
          };
          this.$store.dispatch("setAlert", alert);
        });
      // .finally(() => (this.actions.consult = null));
    },
    updateDocument(body) {
      this.$store.dispatch("axios_post", body).then((res) => {
        if (res.data.success) {
          window.location.reload();
        }
      });
    },
    downloadPDF(event) {
      let file = event.item.attached;
      axios({
        method: "POST",
        baseURL: this.baseURL,
        url: `/get_zip_document`,
        headers: {
          Authorization: `Bearer ${this.auth_token}`,
        },
        data: {
          file,
        },
      })
        .then((response) => {
          function _download_zip(fileName, zip) {
            let element = document.createElement("a");
            element.setAttribute("href", "data:text/plain;base64," + zip);
            element.setAttribute("download", fileName);
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();

            document.body.removeChild(element);
          }

          _download_zip(file, response.data.data);
        })
        .finally(() => (this.$refs[event.ref].actions.download = null));
    },
  },
  created() {
    this.loadFacturas();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista() {
      return this.facturas.map((factura, index) => {
        let estadoDian = "";

        if (factura.dian_state == null && !factura.error_message) {
          estadoDian = "Pendiente";
        } else if (factura.dian_state == false) {
          estadoDian = "Rechazada";
        } else {
          estadoDian = "Aprobada";
        }
        factura.fecha = moment(factura.fecha).format("D MMM YYYY");
        factura.folio = this.$options.filters.folio(factura.folio);

        return Object.assign(factura, {
          estadoDian,
        });
      });
    },
    lista_notas() {
      return this.notas.map((factura) => {
        let estadoDian = "";

        if (factura.dian_state == null && !factura.error_message) {
          estadoDian = "Pendiente";
        } else if (factura.dian_state == false) {
          estadoDian = "Rechazada";
        } else {
          estadoDian = "Aprobada";
        }

        let fecha = moment(factura.created_at, "YYYY-MM-DD HH:mm:ss").format(
          "D MMM YYYY"
        );
        factura.folio = this.$options.filters.folio(factura.folio);
        return Object.assign({ estadoDian, fecha }, factura);
      });
    },
  },
  watch: {
    tab(val) {
      if (val == "tab-1") this.color = "primary";
      if (val == "tab-2") this.color = "error";
      return this.tab;
    },
  },
};
</script>
